import React from 'react'

import SEO from '../components/seo'
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout'

import { Telephone, Envelope, Building } from 'react-bootstrap-icons';

import HeroPage from '../components/views/hero-page'

export default function Contact() {
  return (
    <Layout>
      <style>{`
        .contact-text-size {
          font-size: 30px;
        }
      `}</style>
      <SEO title="Холбоо барих" img={`/img/c1.jpg`} />
      <HeroPage
        title="Холбоо барих"
        img="c1.jpg"
      />
        <div className="page-container">
            <Container>
                <Row className="mb-5">
                    <Col md={4} className="text-center">
                      <Telephone size="50" className="mb-4" />
                      <div>
                        <a className="contact-text-size" href="tel:+976 9914-4479">+976 9914-4479</a>
                      </div>
                    </Col>
                    <Col md={4} className="text-center">
                      <Envelope size="50" className="mb-4" />
                      <div>
                        <a className="contact-text-size" href="mailto:mgulfira@gmail.com">mgulfira@gmail.com</a>
                      </div>
                    </Col>
                    <Col md={4} className="text-center">
                      <img width="50" src="img/facebook-logo.png" alt="Facebook logo" className="mb-4" />
                      <div>
                        <a className="contact-text-size" target="_blank" rel="noreferrer" href="https://www.facebook.com/%D0%9C%D0%B5%D0%BA%D0%B5%D0%B9-%D0%A1%D0%B0%D0%BD-101715318396833">Мекей Сан</a>
                      </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                      <Building size="50" className="mb-4" />
                      <div className="contact-text-size">
                        Голомт банк 1415102228 Мекей Сан
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
  )
}
